import { gsap } from "gsap";
import Observer from "./Observer";

export default class FadeUp extends Observer {
	constructor({ element }) {
		super({ element });
		this.element = element;
	}

	onEnter() {
		const delay = this.element.getAttribute("data-delay");
		const duration = this.element.getAttribute("data-duration");

		gsap.to(this.element, {
			duration: duration ?? 1,
			delay: delay ?? 0,
			autoAlpha: 1,
			y: "0%",
			yPercent: 0,
			stagger: 0.07,
		});
	}
}
