import { gsap } from "gsap";
import SplitType from "split-type";
import Observer from "./Observer";

export default class TextReveal extends Observer {
	constructor({ element }) {
		super({ element });

		this.delay = element.getAttribute("data-delay");
		this.duration = element.getAttribute("data-duration");

		if (element.children.length > 0) {
			this.element = element.firstElementChild;
		} else {
			this.element = element;
		}
		const splitted = new SplitType(this.element, { types: "lines" });
		this.text = new SplitType(splitted.lines, { types: "lines" });
	}

	onEnter() {
		gsap.to(this.text.lines, {
			delay: this.delay ?? 0,
			duration: this.duration ?? 1.3,
			y: "0%",
			yPercent: 0,
			stagger: 0.15,
			ease: "power3.out",
		});
	}
}
