import {gsap} from "gsap";

export default class ProductOverlay {
  constructor(card, index) {
    this.card = card;
    this.index = index;
    this.overlays = [...document.querySelectorAll(".product-overlay")];
    this.closeButtons = [
      ...document.querySelectorAll(".product-overlay__close"),
    ];

    this._clickEvent();
  }

  _getAnimationElements(index) {
    this.overlay = this.overlays[index];

    this.infoCard = this.overlay.querySelector(".info-card");
    this.inforCardTop = this.infoCard.querySelector(".info-card__top-wrapper");
    this.inforCardMiddle = this.infoCard.querySelector(".info-card__middle");
    this.inforCardBottom = this.infoCard.querySelector(
      ".info-card__bottom-wrapper"
    );

    this.graphic = this.overlay.querySelector(".info-card__image");
    this.amounts = [
      ...this.overlay.querySelectorAll(".info-card__amount span"),
    ];
  }

  _clickEvent() {
    this.card.addEventListener("click", () => {
      this._getAnimationElements(this.index);
      this._closeOverlays(this.index);
      this._openOverlay(this.index);
      this._animateOverlayIn(this.index);

      document.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          this._animateOverlayOut(this.index);
        }
      });

      this.closeButtons[this.index].addEventListener("click", () => {
        this._animateOverlayOut(this.index);
      });
    });
  }

  _closeOverlays(index) {
    const allButActiveOverlay = this.overlays.filter((overlay) => {
      return overlay !== this.overlays[index];
    });

    allButActiveOverlay.forEach((overlay) => (overlay.style.display = "none"));
  }

  _setInitialStyles() {
    gsap.set(this.amounts, {
      autoAlpha: 0,
      x: -4,
      overwrite: true,
    });

    //prettier-ignore
    gsap.set([this.inforCardTop, this.inforCardMiddle, this.inforCardBottom, this.graphic], {
      y: 24,
      autoAlpha: 0,
      overwrite: true,
    });
  }

  _animateOverlayOut() {
    //prettier-ignore
    gsap.to(this.overlay, {
      duration: 0.2,
      autoAlpha: 0,
      ease: "none",
      onComplete: () => {
        this.overlay.style.display = "none";
        this._setInitialStyles();
      },
    });
  }

  _openOverlay(index) {
    this.overlay = this.overlays[index];
    this.overlay.style.opacity = "0";
    this.overlay.style.display = "flex";

    gsap.to(this.overlay, {
      autoAlpha: 1,
      duration: 0.3,
    });
  }

  _animateOverlayIn() {
    const tl = gsap.timeline({
      delay: 0.3,
      defaults: {
        autoAlpha: 1,
        duration: 2,
        ease: "expo.out",
      },
    });

    //prettier-ignore
    tl.to([this.infoCard, this.graphic], {
      y: 0,
      yPercent: 0,
    }).to([this.inforCardTop, this.inforCardMiddle, this.inforCardBottom], {
      y: 0,
      yPercent: 0,
      stagger: 0.1,
    }, 0)
    .to(this.amounts, {
      x: 0,
      xPercent: 0,
     stagger: 0.01,
    }, 0);
  }
}
