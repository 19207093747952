const filterButtons = [...document.querySelectorAll(".filters__filter")];
const products = [...document.querySelectorAll(".product-card[data-category]")];

if (filterButtons) {
  filterButtons.forEach((button) => {
    button.addEventListener("click", (e) => {
      e.preventDefault();

      filterButtons.forEach((b) => b.classList.remove("active"));
      button.classList.add("active");
      const category = button.getAttribute("data-category");
      const filteredProducts = products.filter((product) => {
        return product.getAttribute("data-category") == category;
      });

      products.forEach((product) => product.classList.remove("active"));
      filteredProducts.forEach((product) => product.classList.add("active"));
    });
  });
}
