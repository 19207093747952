import "./Home";
import "./ScrollUp";
import TextReveal from "./defaults/TextReveal";
import FadeUp from "./defaults/FadeUp";

const textReveals = [
  ...document.querySelectorAll('[data-animation="text-reveal"]'),
];

textReveals.forEach((item) => {
  new TextReveal({
    element: item,
  });
});

const fadeUps = [...document.querySelectorAll('[data-animation="fade-up"]')];

fadeUps.forEach((item) => {
  new FadeUp({
    element: item,
  });
});
