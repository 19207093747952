import {gsap} from "gsap";
import SplitType from "split-type";

export default class ClickableSlider {
  constructor(slider, index) {
    this.slider = slider;
    this.slideContent = undefined;
    this.titles = [...slider.querySelectorAll("[data-slider-title]")];
    this.slides = [...slider.querySelectorAll("[data-slider-slide]")];
    this.slide = this.slides[0];

    this._clickEvent();
  }

  _updateCurrentSlide(index) {
    this.slide = this.slides[index];
  }

  _setInitialStyles() {
    gsap.set(this.slide, {y: 32, autoAlpha: 0});
  }

  _animateSlideOut(index) {
    gsap.to(this.slide, {
      y: -40,
      autoAlpha: 0,
      duration: 0.5,
      ease: "power2.in",
      onComplete: () => {
        this._closeOtherSlides();
        this.slides[index].classList.add("active");
      },
    });
  }

  _animateSlideIn() {
    gsap.to(this.slide, {
      y: 0,
      autoAlpha: 1,
      duration: 1.5,
      ease: "power3.out",
      delay: 0.5,
    });
  }

  _clickEvent() {
    this.titles.forEach((title, index) => {
      title.addEventListener("click", () => {
        this._removeActiveTitles();
        this._setActiveTitle(title);
        this._animateSlideOut(index);

        this._updateCurrentSlide(index);
        this._setInitialStyles();
        this._animateSlideIn();
      });
    });
  }

  _closeOtherSlides() {
    this.slides.forEach((slide) => slide.classList.remove("active"));
  }

  _removeActiveTitles() {
    this.titles.forEach((title) => title.classList.remove("active"));
  }

  _setActiveTitle(title) {
    title.classList.add("active");
  }
}
