import "@/styles/index.scss";
import "./animation/index";
import "./modules/ProductFilter";

import ProductOverlay from "./modules/ProductOverlay";
import ClickableSlider from "./modules/ClickableSlider";

class App {
  constructor() {
    this._createProductOverlays();
    this._createClickableSliders();
    this._addEventListeners();
    this._render();
  }

  _createProductOverlays() {
    const productCards = [...document.querySelectorAll(".product-card")];
    if (!productCards && !productCards.length) return;

    productCards.forEach((card, index) => {
      new ProductOverlay(card, index);
    });
  }

  _createClickableSliders() {
    const sliders = [...document.querySelectorAll(".clickable-slider")];
    if (!sliders && !sliders.length) return;

    sliders.forEach((slider, index) => {
      new ClickableSlider(slider, index);
    });
  }

  _onMouseMove(event) {}

  _onResize(event) {}

  _addEventListeners() {
    window.addEventListener("resize", this._onResize.bind(this));
    window.addEventListener("mousemove", this._onMouseMove.bind(this));
  }

  _render(time) {
    window.requestAnimationFrame(this._render.bind(this));
  }
}

new App();

console.log(
  "%c Developed by ANTI - https://anti.as/",
  "background: #000; color: #fff;"
);
