const scrollButton = document.querySelector(".scroll-up");

const scrollFunction = () => {
  const shouldBeVisible =
    document.body.scrollTop > 500 || document.documentElement.scrollTop > 500;
  scrollButton.classList.toggle("active", shouldBeVisible);
};

window.onscroll = () => scrollFunction();

const goToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

scrollButton.addEventListener("click", goToTop);
