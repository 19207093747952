import {gsap} from "gsap";
import SplitType from "split-type";

const HERO = document.querySelector(".hero");
const HERO_IMAGE = document.querySelector(".hero img");
const HERO_HEADING = document.querySelector(".hero__heading");
const HEADER_LOGO = document.querySelector(".header__logo");
const HEADER_TEXT = document.querySelector(".header__text");
const MENU_ITEMS = [...document.querySelectorAll(".menu-item")];
const HEADING_SPLITTED = new SplitType(HERO_HEADING, {types: "words, chars"});

const MASTER_TIMELINE = gsap.timeline({
  delay: 0,
});

gsap.defaults({
  ease: "power3.inOut",
  duration: 2,
});

const setInitialStyles = () => {
  gsap.set(HERO, {scale: 1.02});
  gsap.set(HERO_IMAGE, {scale: 1.05});
};

const revealTitle = () => {
  return gsap.to(HEADING_SPLITTED.chars, {
    y: 0,
    yPercent: 0,
    stagger: 0.01,
  });
};

const zoomHero = () => {
  return gsap.to(HERO, {
    scale: 1,
    borderRadius: "16px",
  });
};

const zoomHeroImage = () => {
  return gsap.to(HERO_IMAGE, {
    scale: 1,
  });
};
const revealHeroImage = () => {
  return gsap.to(HERO_IMAGE, {
    autoAlpha: 1,
  });
};

const revealElements = () => {
  return gsap.to([HEADER_LOGO, HEADER_TEXT, MENU_ITEMS], {
    y: 0,
    yPercent: 0,
    autoAlpha: 1,
    stagger: 0.1,
  });
};

//prettier-ignore
MASTER_TIMELINE
  .add(setInitialStyles(), 0)
  .add(revealHeroImage(), 0)
  .add(zoomHeroImage(), '<')
  .add(revealTitle(), '<')
  .add(zoomHero(), '<')
  .add(revealElements(), '<');
